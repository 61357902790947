import { FC } from "react";

const ClappingHandsIcon: FC = () => (
    <>
        <svg
            fill="none"
            height="64"
            viewBox="0 0 64 64"
            width="64"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="32" cy="32" fill="#007db3" r="32" />
            <path
                clipRule="evenodd"
                d="m37.2783 15.4842c.6952-.6971.6952-1.8274 0-2.5245-.6951-.6971-1.8221-.6971-2.5173 0l-7.091 7.1111c.9392.8761 1.531 2.0631 1.6664 3.3423zm-20.3867 20.4977v7.2889c-.0089.1302-.0089.2609 0 .3911l-1.1169-1.1022c-1.0794-1.1692-1.6364-2.7294-1.5423-4.32v-7.2889c0-1.8527.7307-3.3845 1.4958-4.9885.6298-1.3203 1.283-2.6896 1.5711-4.327l.7977-4.6045c.0898-.48.5489-.7976 1.0282-.7111 1.058.1498 1.9238.92 2.1983 1.9556.0535.24.0535.4889 0 .7289l-1.7728 10.4c-1.6781 1.7802-2.6273 4.1282-2.6591 6.5777zm26.3078 1.5111 3.5455-3.5555h-.0886c.6951-.6971 1.8221-.6971 2.5173 0 .6951.6971.6951 1.8273 0 2.5244l-9.2007 9.2089c-.5843.5862-1.2465 1.0887-1.9677 1.4934l-5.4779 3.0577c-1.5806.8718-3.3548 1.3303-5.1587 1.3334h-.0886c-1.4101-.0013-2.7619-.564-3.7583-1.5645l-2.411-2.4c-1.0858-1.1663-1.6494-2.7269-1.56-4.32v-7.2889c0-1.867.7421-3.4299 1.5136-5.0549.6243-1.3149 1.2679-2.6704 1.5533-4.2606l.7977-4.6222c.0897-.4801.5489-.7977 1.0282-.7111 1.2572.2144 2.1821 1.299 2.1983 2.5777v7.4845l13.4375-13.4045c.4497-.4509 1.1051-.627 1.7194-.462.6142.1651 1.094.6462 1.2586 1.2622.1646.6161-.011 1.2733-.4607 1.7243l-8.261 8.2666c-.3476.3486-.3476.9137 0 1.2623.3475.3485.911.3485 1.2586 0l9.4134-9.44c.3329-.3366.786-.5259 1.2586-.5259.4727 0 .9258.1893 1.2587.5259.6873.6934.6873 1.8132 0 2.5066l-9.4134 9.4045c-.1678.1669-.2622.3941-.2622.6311s.0944.4642.2622.6311c.3447.3388.8963.3388 1.241 0l7.3924-7.3778c.3329-.3365.786-.5258 1.2587-.5258.4726 0 .9258.1893 1.2586.5258.6874.6934.6874 1.8133 0 2.5067l-7.3038 7.3244c-.1678.1669-.2622.3941-.2622.6311 0 .2371.0944.4642.2622.6311.3447.3388.8963.3388 1.241 0z"
                fill="#f6f7f9"
                fillRule="evenodd"
            />
        </svg>
    </>
);

export default ClappingHandsIcon;
