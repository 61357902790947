import ChevronIcon from "icons/ts/Chevron";

import IVerifyLogo from "icons/ts/iVerify";
import SettingsIcon from "icons/ts/SettingsIcon";
import SignOutIcon from "icons/ts/SignOutIcon";
import { AppRoutes } from "main/app/App";
import Analytics, { Events } from "main/utils/Analytics";
import { FC, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.scss";

export interface HeaderProps {
    session?: {
        /**
         * The username to show on the account settings dropdown
         */
        user: { name: string };

        /**
         * The name of the organization for the current session
         */
        organization: { name: string };
    };
}

/**
 * The main header for the iVerify site.
 * There are a couple of elements that are unique in the design, and thus have one-off css
 * classes (`.iverify-name`, `.username`, and `.organization-name`)
 */
export const Header: FC<HeaderProps> = ({ session }: HeaderProps) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const toggleDropdown = () => {
        const visible = !showDropdown;

        setShowDropdown(visible);
        Analytics.event(Events.show_dropdown, {
            visible,
        });
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        const pageClickEvent = (e: MouseEvent) => {
            if (
                dropdownRef.current !== null &&
                // @ts-ignore
                !dropdownRef.current.contains(e.target)
            ) {
                setShowDropdown(!showDropdown);
            }
        };
        if (showDropdown) {
            window.addEventListener("click", pageClickEvent);
        }

        return () => {
            window.removeEventListener("click", pageClickEvent);
        };
    }, [showDropdown, dropdownRef]);

    const accountMenu = () => {
        if (session === undefined || session === null || !session.user.name) {
            return <></>;
        }

        const { user, organization } = session;

        return (
            <>
                <Link
                    to={AppRoutes.dashboardPath}
                    className="mrs color-mid-p-1 organization-name"
                    data-testid="organization-name"
                >
                    {organization.name}
                </Link>
                <div style={{ position: "relative" }}>
                    <button
                        name="account-menu"
                        className="account-menu flex-row flex-vc"
                        onClick={toggleDropdown}
                        tabIndex={0}
                    >
                        <div
                            ref={dropdownRef}
                            className="username flex-1"
                            data-testid="username"
                        >
                            {user.name}
                        </div>
                        <ChevronIcon rotate={showDropdown ? 180 : 0} />
                    </button>
                    {showDropdown && (
                        <ul className="header-dropdown-menu" role="menu">
                            <li className="header-dropdown-item">
                                <Link to={AppRoutes.settingsPath}>
                                    Settings
                                    <SettingsIcon />
                                </Link>
                            </li>

                            <li className="header-dropdown-item">
                                <Link to={AppRoutes.logoutPath}>
                                    Sign Out
                                    <SignOutIcon />
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>
            </>
        );
    };

    return (
        <header className="flex-row flex-vc flex-hb phm color-bg-dark max-h-vh-10">
            <div className="flex-ac">
                <Link to={AppRoutes.dashboardPath}>
                    <IVerifyLogo />
                    <div className="iverify-name">iVerify</div>
                </Link>
            </div>
            <div className="flex-row uppercase font-rubik text-lg">
                <NavLink
                    to={AppRoutes.dashboardPath}
                    className={({ isActive }) =>
                        [
                            isActive ? "color-light-m-1" : "color-mid-p-1",
                            "px-4 !font-medium",
                        ].join(" ")
                    }
                >
                    Devices
                </NavLink>

                <NavLink
                    to={`${AppRoutes.alertsConsolePath}?status=open`}
                    className={({ isActive }) =>
                        [
                            isActive ? "color-light-m-1" : "color-mid-p-1",
                            "px-4 !font-medium",
                        ].join(" ")
                    }
                >
                    Alerts
                </NavLink>
            </div>
            <div className="flex-ac flex-row">{accountMenu()}</div>
        </header>
    );
};

export default Header;
