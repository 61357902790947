/* eslint-disable jsx-a11y/no-static-element-interactions,react/jsx-props-no-spreading */
import { HeaderGroup } from "react-table";
import { DeviceRow } from "main/dashboard/types";
import Analytics, { Events } from "main/utils/Analytics";
import TableLoadingIndicator from "./TableLoadingIndicator";
import sortingArrow from "./sortingArrow";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortColumn = (column: string | any, asc: boolean | undefined): void => {
    // first click sort is undefined because asc param is the previous state
    const name = typeof column === "string" ? column : "unknown";
    Analytics.event(Events.sort_dashboard_column, {
        column: name,
        ascending: asc,
    });
};

const getHeaderCell = (headerGroup: HeaderGroup<DeviceRow>): JSX.Element => {
    const {
        id,
        canSort,
        isSorted,
        Header,
        isSortedDesc,
        getHeaderProps,
        getSortByToggleProps,
        render,
    } = headerGroup;

    // @ts-ignore
    const { key, onClick } = getHeaderProps(getSortByToggleProps());

    return (
        <th className={`${id} border-y-0`} key={key} onClick={onClick}>
            <div
                className="flex flex-row"
                onClick={() => sortColumn(Header, isSortedDesc)}
                onKeyDown={() => sortColumn(Header, isSortedDesc)}
            >
                <>
                    <span>
                        <>{render("Header")}</>
                    </span>
                    {canSort && sortingArrow(isSorted, isSortedDesc)}
                </>
            </div>
        </th>
    );
};

const getTableHeader = (
    headerGroups: Array<HeaderGroup<DeviceRow>>,
    loading: boolean
): JSX.Element => (
    <thead className="color-bg-light-m-1 sticky -top-[0.1px] z-10">
        {headerGroups.map((headers) => {
            const { key } = headers.getHeaderGroupProps();
            return (
                <>
                    <tr key={key}>
                        {headers.headers.map((cell: HeaderGroup<DeviceRow>) =>
                            getHeaderCell(cell)
                        )}
                    </tr>
                    <TableLoadingIndicator loading={loading} />
                </>
            );
        })}
    </thead>
);

export default getTableHeader;
