/* eslint-disable react/jsx-props-no-spreading */
import {
    Row,
    TableOptions,
    useBlockLayout,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import dashboardColumns from "main/dashboard/columns";
import getTableBody from "main/dashboard/components/TableBody";
import getTableHeader from "main/dashboard/components/TableHeader";
import { DeviceRow } from "main/dashboard/types";
import { FC, useEffect, useMemo } from "react";
import Tooltip from "components/Tooltip/Tooltip";

interface TableProps {
    data: DeviceRow[];
    loading: boolean;
    setSelections: (rows: DeviceRow[]) => void;
}

const Table: FC<TableProps> = (props: TableProps) => {
    const { data, loading, setSelections } = props;
    const columns = useMemo(() => dashboardColumns, []);

    const defaultColumn = useMemo(
        () => ({
            defaultCanSort: true,
            disableSortBy: false,
            canSort: true,
        }),
        []
    );

    const tableOptions: TableOptions<DeviceRow> = {
        columns,
        data,
        defaultColumn,
        disableSortRemove: false,
    };

    const { headerGroups, rows, prepareRow, selectedFlatRows } = useTable(
        tableOptions,
        useSortBy,
        useRowSelect,
        useBlockLayout
    );

    // Update row selections for the dashboard
    useEffect(() => {
        setSelections(
            selectedFlatRows.map((row: Row<DeviceRow>) => row.original)
        );
    }, [setSelections, selectedFlatRows]);

    return (
        <>
            <table className="border border-light-m-1">
                {getTableHeader(headerGroups, loading)}
                {rows?.length > 0 && getTableBody(prepareRow, rows)}
            </table>
            <Tooltip id="table-row-tooltip" clickable />
            <Tooltip id="table-cell-tooltip" clickable />
        </>
    );
};

export default Table;
