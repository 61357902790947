/* eslint-disable react/jsx-props-no-spreading */
import { TableAlertIcon, TableWarningIcon } from "icons/ts/AlertIcon";
import { Cell, UseRowSelectInstanceProps } from "react-table";
import TableCheckbox from "main/dashboard/components/TableCheckbox";
import {
    DeviceInformation,
    DeviceRow,
    TableRowJSX,
    TableColumn,
} from "main/dashboard/types";
import moment from "moment";
import { Link } from "components/Link";
import { AppRoutes } from "main/app/App";
import { toggleTooltip } from "components/Tooltip/Tooltip";

const customSortFunction = (
    rowA: TableRowJSX,
    rowB: TableRowJSX,
    columnId: string
): number => {
    const valueA = rowA?.values[columnId]?.props["data-sort"];
    const valueB = rowB?.values[columnId]?.props["data-sort"];

    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }
    return 0;
};

const usingBiometrics = (device: DeviceRow): JSX.Element => {
    if (!("biometrics" in device)) {
        return <></>;
    }
    if (device.biometrics) {
        return <>Yes</>;
    }
    if ("isWorkProfile" in device && device.isWorkProfile) {
        return (
            <>
                No
                <span
                    data-tooltip-id="table-cell-tooltip"
                    data-tooltip-content="Biometrics has not been configured for the work profile. Click to read more."
                >
                    <Link
                        className="color-black font-normal"
                        to={{ pathname: AppRoutes.biometricsPath }}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() =>
                            toggleTooltip("table-row-tooltip", false)
                        }
                        onMouseLeave={() =>
                            toggleTooltip("table-row-tooltip", true)
                        }
                    >
                        <TableAlertIcon color="#65849A" />
                    </Link>
                </span>
            </>
        );
    }
    return <>No</>;
};

const usingNotifications = (device: DeviceRow): string => {
    if ("isNotificationsEnabled" in device) {
        return device.isNotificationsEnabled ? "Yes" : "No";
    }
    return "";
};

const usingScreenLock = (device: DeviceRow): string => {
    if ("screenLock" in device) {
        return device.screenLock ? "Yes" : "No";
    }
    return "";
};

const dateOptions = { year: "2-digit" as const, month: "2-digit" as const };
const usingDate = (device: DeviceRow): string => {
    if ("added" in device) {
        const dateObject = new Date(device.added);
        return dateObject.toLocaleString("en-US", dateOptions);
    }
    return "";
};

export const isPreviouslyInsecure = (device: DeviceInformation): boolean => {
    if (
        !device.secure &&
        device.latestInsecureScanDate &&
        device.latestInsecureScanDate < device.latestScanDate
    ) {
        return true;
    }
    return false;
};

export const threatDetectedTooltipMessage = (
    device: DeviceInformation
): string => {
    let dateTimeAgo = "sometime ago";
    if (device.latestInsecureScanDate != null) {
        dateTimeAgo = moment(device.latestInsecureScanDate).fromNow();
    }

    const prefix = isPreviouslyInsecure(device)
        ? `Latest in-app scan is secure, but a threat was detected ${dateTimeAgo}`
        : `Threats detected ${dateTimeAgo}`;

    if (device.threats == null || device.threats.length === 0) {
        return `Latest threat detected via in-app scan was ${dateTimeAgo}`;
    }

    let htmlString = `<p>${prefix}:</p><ul class="list-disc pl-3">`;

    device?.threats?.forEach((threat) => {
        htmlString += `<li>${threat.description}</li>`;
    });

    htmlString += "</ul>";
    htmlString += "See Alerts Console for more details.";
    return htmlString;
};

const threatDetected = (device: DeviceRow): JSX.Element => {
    const deviceInfo = device as DeviceInformation;
    let isThreatDetectedText = "N/A";
    let showThreatIcon = false;

    if (!deviceInfo.enrolled) {
        return <span data-sort="Not Enrolled">Not enrolled</span>;
    }

    if ("latestInsecureScanDate" in deviceInfo) {
        if (deviceInfo.latestInsecureScanDate != null) {
            showThreatIcon = true;
        }
    }

    if (deviceInfo?.secure === true) {
        isThreatDetectedText = "No";
    } else if (deviceInfo?.secure === false) {
        isThreatDetectedText = "Yes";
        showThreatIcon = true;
    }

    return (
        <span data-sort={isThreatDetectedText}>
            {isThreatDetectedText}
            {showThreatIcon &&
                (isPreviouslyInsecure(deviceInfo) ? (
                    <TableAlertIcon />
                ) : (
                    <span
                        data-tooltip-id="table-cell-tooltip"
                        data-tooltip-html={threatDetectedTooltipMessage(
                            deviceInfo
                        )}
                    >
                        <TableAlertIcon />
                    </span>
                ))}
            {deviceInfo?.isPlayProtectEnabled === false ? (
                <span
                    data-tooltip-id="table-cell-tooltip"
                    data-tooltip-content="Google Play Protect is disabled on this device"
                >
                    <TableWarningIcon />
                </span>
            ) : (
                deviceInfo?.secure == null && (
                    <span
                        data-tooltip-id="table-cell-tooltip"
                        data-tooltip-content="The last scan was not completed"
                    >
                        <TableAlertIcon color="#65849A" />
                    </span>
                )
            )}
        </span>
    );
};

const latestOSVersionMessage = (device: DeviceInformation): string => {
    if (device.platform != null && device.latestOSVersion != null) {
        if (device.platform === "Android") {
            return `${device.platform} security patch version ${device.latestOSVersion} has been released. This device is running ${device.securityPatchVersion}.`;
        }
        return `${device.platform} ${device.latestOSVersion} is available for update`;
    }

    return "OS update is available for download";
};

const latestOS = (device: DeviceRow): JSX.Element => {
    if ("osVersionState" in device) {
        if (device.osVersionState === "SECURITY_PATCH_OUTDATED") {
            return (
                <>
                    {device.os}
                    <span
                        className="p-0"
                        data-tooltip-id="table-cell-tooltip"
                        data-tooltip-content={latestOSVersionMessage(device)}
                        onMouseEnter={() =>
                            toggleTooltip("table-row-tooltip", false)
                        }
                        onMouseLeave={() =>
                            toggleTooltip("table-row-tooltip", true)
                        }
                    >
                        <TableWarningIcon />
                    </span>
                </>
            );
        }
        if (device.osVersionState === "OUT_OF_DATE") {
            return (
                <>
                    {device.os}
                    <span
                        data-tooltip-id="table-cell-tooltip"
                        data-tooltip-content={latestOSVersionMessage(device)}
                        onMouseEnter={() =>
                            toggleTooltip("table-row-tooltip", false)
                        }
                        onMouseLeave={() =>
                            toggleTooltip("table-row-tooltip", true)
                        }
                    >
                        <TableAlertIcon />
                    </span>
                </>
            );
        }

        if (device.osVersionState === "LATEST_FOR_DEVICE") {
            return (
                <>
                    {device.os}
                    <span>
                        <TableWarningIcon deviceName={device.device} />
                    </span>
                </>
            );
        }

        // LATEST, UNKNOWN, BETA
        return <>{device.os}</>;
    }

    return <></>;
};

const latestAppVersionMessage = (device: DeviceInformation): string => {
    if (device.platform != null && device.latestAppVersion != null) {
        return `${device.platform} iVerify ${device.latestAppVersion} is available for update`;
    }

    return `New version of iVerify is available for update`;
};

const latestApp = (device: DeviceRow): JSX.Element => {
    if ("version" in device && device.version.length > 0) {
        if (!device.isLatestVersion) {
            return (
                <span data-sort={device.version}>
                    {device.version}
                    <div
                        data-tooltip-id="table-cell-tooltip"
                        data-tooltip-content={latestAppVersionMessage(device)}
                        onMouseEnter={() =>
                            toggleTooltip("table-row-tooltip", false)
                        }
                        onMouseLeave={() =>
                            toggleTooltip("table-row-tooltip", true)
                        }
                    >
                        <TableAlertIcon />
                    </div>
                </span>
            );
        }
        return <span data-sort={device.version}>{device.version}</span>;
    }

    return <></>;
};

const dashboardColumns: Array<TableColumn> = [
    {
        accessor: "selection",
        id: "selection",
        Header({
            getToggleAllRowsSelectedProps,
        }: UseRowSelectInstanceProps<DeviceRow>): JSX.Element {
            return (
                <TableCheckbox
                    data-testid="all-row-checkbox"
                    {...getToggleAllRowsSelectedProps()}
                />
            );
        },
        Cell: ({ row }: Cell<DeviceRow>): JSX.Element => {
            const { memberId: id } = row.original;
            return (
                <TableCheckbox
                    data-testid={`${id}-row-checkbox`}
                    {...row.getToggleRowSelectedProps()}
                />
            );
        },
        disableSortBy: true,
        canSort: false,
    },
    {
        accessor: "name",
        id: "name",
        Header: "Name",
    },
    {
        accessor: "code",
        id: "code",
        Header: "Access Code",
    },
    {
        accessor: (d: DeviceRow): JSX.Element => threatDetected(d),
        id: "secure",
        Header: "Threat Detected",
        sortType: customSortFunction,
    },
    {
        accessor: (d: DeviceRow): string => usingScreenLock(d),
        id: "lock",
        Header: "Screen Lock",
    },
    {
        accessor: (d: DeviceRow): JSX.Element => usingBiometrics(d),
        id: "biometrics",
        Header: "Biometrics",
    },
    {
        accessor: (d: DeviceRow): JSX.Element => latestApp(d),
        id: "version",
        Header: "iVerify Version",
        sortType: customSortFunction,
    },
    {
        accessor: "guidesCompleted",
        id: "guidesCompleted",
        Header: "Guides Completed",
    },
    {
        accessor: "device",
        id: "device",
        Header: "Device",
    },
    {
        accessor: (d: DeviceRow): string => usingNotifications(d),
        id: "isNotificationsEnabled",
        Header: "Enabled Notifications",
    },
    {
        accessor: (d: DeviceRow): JSX.Element => latestOS(d),
        id: "os",
        Header: "OS",
    },
    {
        accessor: (d: DeviceRow): string => usingDate(d),
        id: "added",
        Header: "Added",
    },
];

export default dashboardColumns;
