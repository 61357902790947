import MemberUploadPage from "main/add-members/csv/MemberUploadForm";
import MemberManualUploadPage from "main/add-members/manual/MemberManualUploadPage";
import MemberConfirmationPage from "main/add-members/MemberConfirmationPage";
import MemberManagementPage from "main/add-members/MemberManagementPage";
import GroupSelectionPage from "main/add-members/sso/GroupSelectionPage";
import SsoSettingsPage from "main/add-members/sso/SSOSettingsPage";
import { AppRoutes } from "main/app/App";
import DashboardPage from "main/dashboard/DashboardPage";
import HeaderLayout from "main/HeaderLayout";
import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import SSOSelectionPage from "main/add-members/sso/SSOSelectionPage";
import { FC, useEffect } from "react";
import { displayCustomBanner } from "main/utils/displayBanner";
import { AlertWarningIcon } from "icons/ts/AlertIcon";
import AlertsConsolePage from "main/alerts/AlertsConsolePage";
import { ApplicationState, SeverityType } from "./types";

const ProtectedRoutes: FC = () => {
    const navigate = useNavigate();

    const session = useSelector((state: ApplicationState) => state.session);

    useEffect(() => {
        if (session?.error?.severity === SeverityType.Error) {
            navigate(AppRoutes.errorPath);
        } else if (session?.error?.severity === SeverityType.Warning) {
            displayCustomBanner(
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                    data-testid="banner"
                    onClick={() => navigate("/settings?tab=billing")}
                    className="display-flex flex-hb"
                >
                    <div>
                        <AlertWarningIcon />
                        {session?.error?.description}
                    </div>
                    <Link to="/settings?tab=billing">Go to Billing</Link>
                </div>,
                {
                    type: "warning",
                    autoClose: false,
                    closeOnClick: false,
                    closeButton: false,
                    icon: false,
                }
            );
        }
    }, [session, navigate]);

    return (
        <HeaderLayout session={session}>
            <Routes>
                <Route
                    path={AppRoutes.ssoSetupPath}
                    element={<SsoSettingsPage />}
                />
                <Route
                    path={AppRoutes.ssoSelectionPath}
                    element={<SSOSelectionPage />}
                />
                <Route
                    path={AppRoutes.dashboardPath}
                    element={<DashboardPage />}
                />
                <Route
                    path={AppRoutes.memberManagementPath}
                    element={<MemberManagementPage />}
                />

                <Route
                    path={AppRoutes.memberGroupsPath}
                    element={<GroupSelectionPage />}
                />
                <Route
                    path={AppRoutes.memberConfirmPath}
                    element={<MemberConfirmationPage />}
                />
                <Route
                    path={AppRoutes.memberUploadPath}
                    element={<MemberUploadPage />}
                />
                <Route
                    path={AppRoutes.memberManualUploadPath}
                    element={<MemberManualUploadPage />}
                />
                <Route
                    path={AppRoutes.alertsConsolePath}
                    element={<AlertsConsolePage />}
                />

                <Route
                    path="/"
                    element={<Navigate replace to={AppRoutes.dashboardPath} />}
                />
                <Route
                    path="*"
                    element={<Navigate replace to={AppRoutes.dashboardPath} />}
                />
            </Routes>
        </HeaderLayout>
    );
};

export default ProtectedRoutes;
