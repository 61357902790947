import Header from "components/header/Header";
import { SessionState } from "main/app/types";
import NotificationBanner from "main/NotificationBanner";
import { FC, HTMLAttributes } from "react";

export interface HeaderLayoutProps extends HTMLAttributes<HTMLDivElement> {
    session: SessionState;
}

const HeaderLayout: FC<HeaderLayoutProps> = (
    props: HeaderLayoutProps
): JSX.Element => {
    const { session, children } = props;
    return (
        <>
            <Header session={session} />
            <NotificationBanner />
            {children}
        </>
    );
};

export default HeaderLayout;
