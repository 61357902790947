import { FC, MouseEventHandler } from "react";
import { Button } from "components/button/Button";
import ClappingHands from "icons/ts/ClappingHands";
import { useAddMembersHint } from "main/app/AppCookies";

import "./AddMembersHint.scss";

export interface AddMembersHintProps {
    hasMembers: boolean;
    addTeamMembersOnClick?: MouseEventHandler | undefined;
}

export const AddMembersHint: FC<AddMembersHintProps> = ({
    hasMembers,
    addTeamMembersOnClick,
}: AddMembersHintProps) => {
    const { shouldHideAddMembersHint, setHideAddMembersHint } =
        useAddMembersHint();

    if (hasMembers === true && !shouldHideAddMembersHint) {
        setHideAddMembersHint();
    }
    if (shouldHideAddMembersHint === true) {
        return <div data-testid="add-members-hint" />;
    }

    return (
        <div
            className="add-members-hint flex-col flex-vc pvm"
            data-testid="add-members-hint"
        >
            <ClappingHands />
            <div className="title-three mtm">Congratulations!</div>
            <div className="paragraph-one mtm">
                You’re the first member of the Organization, let’s add more
                members of your team!
            </div>
            <div className="flex-row flex-hc mtm">
                <Button
                    appearance="tertiary"
                    label="Later"
                    className="mrs"
                    onClick={setHideAddMembersHint}
                />
                <Button
                    label="+ Add Team Members"
                    onClick={addTeamMembersOnClick}
                />
            </div>
            <div className="mid-m-1 italic mtm">
                This is always available in the main actions of the dashboard.
            </div>
        </div>
    );
};

export default AddMembersHint;
