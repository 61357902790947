import { FC } from "react";

const SignOutIcon: FC = () => (
    <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        style={{ verticalAlign: "middle" }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.33333 11.1667H5C5.18409 11.1667 5.33333 11.3159 5.33333 11.5V12.1667C5.33333 12.3508 5.18409 12.5 5 12.5H1.33333C0.596954 12.5 0 11.903 0 11.1667V1.83333C0 1.09695 0.596954 0.5 1.33333 0.5H5C5.18409 0.5 5.33333 0.649238 5.33333 0.833333V1.5C5.33333 1.68409 5.18409 1.83333 5 1.83333H1.33333V11.1667ZM8.42667 2.6L11.8533 6.02C11.9459 6.11448 11.9985 6.24106 12 6.37333V6.62667C11.9999 6.75923 11.9471 6.88632 11.8533 6.98L8.42667 10.4C8.36408 10.4631 8.27888 10.4986 8.19 10.4986C8.10112 10.4986 8.01592 10.4631 7.95333 10.4L7.48 9.93333C7.4169 9.87075 7.3814 9.78555 7.3814 9.69667C7.3814 9.60779 7.4169 9.52259 7.48 9.46L9.78 7.16667H3C2.8159 7.16667 2.66667 7.01743 2.66667 6.83333V6.16667C2.66667 5.98257 2.8159 5.83333 3 5.83333H9.78L7.48 3.54C7.41729 3.47856 7.38195 3.39446 7.38195 3.30667C7.38195 3.21887 7.41729 3.13478 7.48 3.07333L7.95333 2.6C8.01592 2.5369 8.10112 2.5014 8.19 2.5014C8.27888 2.5014 8.36408 2.5369 8.42667 2.6Z"
            fill="#007db2"
        />
    </svg>
);

export default SignOutIcon;
