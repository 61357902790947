/* eslint-disable */
import { Cell, Row } from "react-table";
import { DeviceInformation, DeviceRow } from "main/dashboard/types";
import { isPreviouslyInsecure, threatDetectedTooltipMessage } from "../columns";

const rowClass = (device: DeviceRow): string | undefined => {
    if ("secure" in device && !device.secure) {
        return "low-opacity-bg-critical-lt";
    }

    if ("osVersionState" in device && device.osVersionState === "OUT_OF_DATE") {
        return "low-opacity-bg-critical-lt";
    }

    if (
        "version" in device &&
        device.version.length > 0 &&
        device.isLatestVersion === false
    ) {
        return "low-opacity-bg-critical-lt";
    }

    if (
        "osVersionState" in device &&
        device.osVersionState === "LATEST_FOR_DEVICE"
    ) {
        return "low-opacity-bg-warning-lt";
    }

    if ("isPlayProtectEnabled" in device && !device.isPlayProtectEnabled) {
        return "low-opacity-bg-warning-lt";
    }

    return undefined;
};

const cellClass = (cell: Cell<DeviceRow>): string | undefined => {
    const {
        column: { id },
        row: { original: device },
    } = cell;

    if (id === "code") {
        const codeClass = device.enrolled ? "enrolled " : "";
        return `${codeClass}${id}`;
    }

    if (id === "secure" && !device.enrolled) {
        return `no-scans ${id}`;
    }

    return id;
};

const getTableBody = (
    prepareRow: (row: Row<DeviceRow>) => void,
    page: Array<Row<DeviceRow>>
): JSX.Element => {
    return (
        <tbody>
            {page.map((row) => {
                prepareRow(row);
                const { key } = row.getRowProps();
                const deviceInfo = row.original as DeviceInformation;

                return (
                    <tr
                        className={rowClass(row.original)}
                        key={key}
                        data-tooltip-id="table-row-tooltip"
                        data-tooltip-html={
                            isPreviouslyInsecure(deviceInfo)
                                ? threatDetectedTooltipMessage(deviceInfo)
                                : null
                        }
                        data-tooltip-float
                    >
                        {row.cells.map((cell: Cell<DeviceRow>) => {
                            const { key } = cell.getCellProps();
                            return (
                                <td className={cellClass(cell)} key={key}>
                                    <>{cell.render("Cell")}</>
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default getTableBody;
