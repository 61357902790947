import { AppRoutes } from "main/app/App";
import { ApplicationState, SessionState } from "main/app/types";
import HeaderLayout from "main/HeaderLayout";
import AdminTab from "main/settings/AdminTab";
import BillingTab from "main/settings/BillingTab";
import PlanTab from "main/settings/PlanTab";
import useQuery from "main/utils/useQuery";
import { FC, HTMLAttributes, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScimTab from "main/settings/ScimTab";
import SsoTab from "main/settings/SsoTab";
import Footer from "main/Footer";
import IntegrationsTab from "./IntegrationsTab";
import MdmTab from "./MdmTab";
import ThreatHunterTab from "./ThreatHunterTab";
import NotificationsTab from "./NotificationsTab";

export interface SettingButton extends HTMLAttributes<HTMLButtonElement> {
    label: string;
    tab: string;
    currentTab: string;
    showTab: (tab: string) => () => void;
}

export const SettingsButton: FC<SettingButton> = ({
    label,
    tab,
    currentTab,
    showTab,
    ...props
}: SettingButton) => {
    const selected = currentTab === tab ? "selected" : "";
    return (
        <button
            className={`settings-choice ${selected}`}
            onClick={showTab(tab)}
            {...props}
        >
            {label}
        </button>
    );
};

interface SettingsPageProps {
    session: SessionState;
    currentTabContent: (currentTab: string) => JSX.Element;
}

export const SettingsPages = {
    billing: "billing",
    admin: "admin",
    plan: "plan",
    branding: "branding",
    scim: "scim",
    sso: "sso",
    integrations: "integrations",
    mdm: "mdm",
    threatHunter: "threat-hunter",
    notifications: "notifications",
};

export const DisconnectedSettingsPage: FC<SettingsPageProps> = (
    props: SettingsPageProps
) => {
    const { session, currentTabContent } = props;
    const query = useQuery();
    const navigate = useNavigate();
    const { tab: startingTab } = query;
    const [currentTab, setCurrentTab] = useState<string>(
        startingTab ?? SettingsPages.admin
    );

    const onClick = (tab: string) => () => {
        setCurrentTab(tab);
        navigate(`${AppRoutes.settingsPath}?tab=${tab}`, { replace: true });
    };

    return (
        <HeaderLayout session={session}>
            <div className="pam special-case-title color-dark">Settings</div>
            <div className="line flex-row flex-hs">
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="Admin"
                    tab={SettingsPages.admin}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="Billing"
                    tab={SettingsPages.billing}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="Plan"
                    tab={SettingsPages.plan}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="MDM"
                    tab={SettingsPages.mdm}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="SCIM"
                    tab={SettingsPages.scim}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="SSO"
                    tab={SettingsPages.sso}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="Integrations"
                    tab={SettingsPages.integrations}
                />
                <SettingsButton
                    currentTab={currentTab}
                    showTab={onClick}
                    label="Notifications"
                    tab={SettingsPages.notifications}
                />
                {session?.portalPreferences?.threatHunterAccess && (
                    <SettingsButton
                        currentTab={currentTab}
                        showTab={onClick}
                        label="Threat Hunter"
                        tab={SettingsPages.threatHunter}
                    />
                )}
            </div>
            <div className="flex-col flex-1">
                <div className="flex-col flex-1">
                    {currentTabContent(currentTab)}
                </div>
                <Footer />
            </div>
        </HeaderLayout>
    );
};

const SettingsPage: FC = () => {
    const session = useSelector((state: ApplicationState) => state.session);

    const currentTabContent = (currentTab: string) => {
        switch (currentTab) {
            case SettingsPages.billing:
                return <BillingTab />;
            case SettingsPages.plan:
                return <PlanTab />;
            case SettingsPages.sso:
                return <SsoTab />;
            case SettingsPages.admin:
                return <AdminTab />;
            case SettingsPages.scim:
                return <ScimTab />;
            case SettingsPages.integrations:
                return <IntegrationsTab />;
            case SettingsPages.mdm:
                return <MdmTab />;
            case SettingsPages.threatHunter:
                return <ThreatHunterTab />;
            case SettingsPages.notifications:
                return <NotificationsTab />;
            default:
                return <></>;
        }
    };

    return (
        <DisconnectedSettingsPage
            session={session}
            currentTabContent={currentTabContent}
        />
    );
};

export default SettingsPage;
