import axios, {
    AxiosInstance,
    AxiosResponse,
    CreateAxiosDefaults,
} from "axios";
import {
    Group,
    GroupMembers,
    Member,
    Members,
    ThreatHunterUser,
    MemberResponse,
} from "main/add-members/types";
import {
    CreateOrgRequest,
    CreateSSOIntegrationRequest,
} from "main/create-account/types";
import {
    ApiTokenExpiration,
    EmailPreferences,
    EmailTemplate,
    EmailTemplateType,
    NotificationPreference,
    InternalNotificationTopic,
    SessionState,
    WebhookConfig,
    ApiConfiguration,
    ConditonalAccessConfig,
    ConditionalAccessPolicy,
} from "main/app/types";
import { DashboardData, DeviceRow } from "main/dashboard/types";
import { removeNulls } from "main/dashboard/hooks";
import { User, StripeSubscriptionStatus } from "main/settings/types";
import { ScimConfiguration } from "main/settings/ScimTab";
import { MdmConfiguration } from "main/settings/MdmTab";
import { AlertRow, AlertStatus } from "main/alerts/types";

export interface NewMembersResponse {
    numMembersCreated: number;
}

export interface CreateSSOResponse {
    slug: string;
}

export interface OrgLookupResponse {
    hasSSO: boolean;
}

export interface EmailMembersResponse {
    count: number;
}

export interface BillingDetails {
    card?: CardDetails;
    subscription: SubscriptionStatus;
}

export interface CardDetails {
    lastFour: string;
    network: string;
}

export interface SubscriptionStatus {
    status: string;
    invoice: boolean;
    amountDue?: number;
    renewDate?: number;
    cancelDate?: number;
}

export interface PlanStatus {
    status: string;
    statusMessage: string;
    relevantDate: number;
}

export interface UpdatePaymentStatus {
    status: StripeSubscriptionStatus;
}

export interface BillingKey {
    clientSecret: string;
}

export class ApiClient {
    private client: AxiosInstance;

    constructor(client: AxiosInstance) {
        this.client = client;
    }

    fetchSession = (): Promise<SessionState> =>
        this.client
            .get("/session")
            .then((response: AxiosResponse) => response.data as SessionState);

    resetPassword = (email: string): Promise<void> =>
        this.client
            .post("/reset-password", { email })
            .then((_response: AxiosResponse) => undefined);

    updatePassword = (requestToken: string, password: string): Promise<void> =>
        this.client
            .post("/update-password", { requestToken, password })
            .then((_response: AxiosResponse) => undefined);

    addAdmin = (name: string, email: string): Promise<void> =>
        this.client
            .post("/orgs/add-admin", { name, email })
            .then((_response: AxiosResponse) => undefined);

    resendAdminInvite = (name: string, email: string): Promise<void> =>
        this.client
            .post("/orgs/resend-admin-invite", { name, email })
            .then((_response: AxiosResponse) => undefined);

    logout = (): Promise<void> =>
        this.client
            .post("/logout")
            .then((_response: AxiosResponse) => undefined);

    login = (email: string, password: string): Promise<SessionState> =>
        this.client
            .post("/login", { email, password })
            .then((response: AxiosResponse) => response.data as SessionState);

    createMembers = (
        newMembers: Member[],
        sendEmail: boolean
    ): Promise<NewMembersResponse> =>
        this.client
            .post(`/orgs/create-members?email=${sendEmail}`, newMembers)
            .then(
                (response: AxiosResponse) => response.data as NewMembersResponse
            );

    createSsoMembers = (
        ssoGroups: Group[],
        allowedDuplicates: Member[],
        sendEmail: boolean
    ): Promise<NewMembersResponse> =>
        this.client
            .post(`/orgs/create-sso-members?email=${sendEmail}`, {
                ssoGroups,
                allowedDuplicates,
            })
            .then(
                (response: AxiosResponse) => response.data as NewMembersResponse
            );

    createOrganization = (request: CreateOrgRequest): Promise<SessionState> =>
        this.client
            .post("/orgs", request)
            .then((response: AxiosResponse) => response.data as SessionState);

    sso = (request: CreateSSOIntegrationRequest): Promise<CreateSSOResponse> =>
        this.client
            .post("/orgs/sso", request)
            .then(
                (response: AxiosResponse) => response.data as CreateSSOResponse
            );

    lookupOrgBySlug = (slug: string): Promise<OrgLookupResponse> =>
        this.client
            .post("/orgs/lookup", { slug })
            .then((response: AxiosResponse) => ({
                hasSSO: response.data.sso,
            }));

    fetchDashboard = (
        page?: number,
        pageSize?: number,
        filters?: { [key: string]: string }
    ): Promise<DashboardData> => {
        let url = `orgs${page ? `?page=${page}` : ""}${
            pageSize ? `&pageSize=${pageSize}` : ""
        }`;

        if (filters) {
            Object.keys(filters).forEach((key) => {
                const value = filters[key];
                if (typeof value === "object") {
                    Object.keys(value).forEach((subKey) => {
                        if (value[subKey]) {
                            url += `&${key}=${subKey}`;
                        }
                    });
                } else {
                    url += `&${key}=${value}`;
                }
            });
        }

        return this.client.get(url).then((response: AxiosResponse) => {
            const { devices } = response.data;
            const parsedDevices: DeviceRow[] = devices.map(removeNulls);
            return { ...response.data, devices: parsedDevices };
        });
    };

    emailMembers = (deviceCodes: string[]): Promise<EmailMembersResponse> =>
        this.client
            .post("/orgs/email-members", deviceCodes)
            .then(
                (response: AxiosResponse) =>
                    response.data as EmailMembersResponse
            );

    emailAllMembers = (): Promise<void> =>
        this.client
            .post("/orgs/email-all-members")
            .then((_response: AxiosResponse) => undefined);

    unenrollDevices = (deviceCodes: string[]): Promise<void> =>
        this.client
            .post("/orgs/unenroll-devices", deviceCodes)
            .then((_response: AxiosResponse) => undefined);

    sendMembersCsv = (csvFile: File): Promise<Members> => {
        const formData = new FormData();
        formData.append("file", csvFile);

        return this.client
            .post("orgs/read-members", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response: AxiosResponse) => response.data as Members);
    };

    getSSOProvider = (): Promise<string> =>
        this.client
            .get("orgs/sso/provider")
            .then((response: AxiosResponse) => response.data as string);

    getSSOGroups = (
        nextPageLink = null,
        searchQuery = null
    ): Promise<{ nextPage: string; groups: Group[] }> => {
        const nextPage = nextPageLink
            ? `?nextPage=${encodeURIComponent(nextPageLink)}`
            : "";
        const searchParam = searchQuery
            ? `?searchQuery=${encodeURIComponent(searchQuery)}`
            : "";
        const ssoLink = `orgs/sso/groups${searchParam}${
            nextPage && searchParam ? `&${nextPage}` : nextPage
        }`;
        return this.client.get(ssoLink).then((response: AxiosResponse) => ({
            nextPage: response.data.nextPage ?? null,
            groups: response.data.groups as Group[],
        }));
    };

    fetchMembersInGroup = (
        selectedGroupsRequest: Group[]
    ): Promise<GroupMembers> =>
        this.client
            .post("orgs/sso/groups", selectedGroupsRequest)
            .then((response: AxiosResponse) => response.data as GroupMembers);

    getBillingDetails = (): Promise<BillingDetails> =>
        this.client
            .get("/customer-billing-info")
            .then((response: AxiosResponse) => {
                const parsedData = removeNulls(response.data);
                return parsedData as unknown as BillingDetails;
            });

    getPlanDetails = (): Promise<PlanStatus> =>
        this.client
            .get("/customer-plan-info")
            .then((response: AxiosResponse) => response.data as PlanStatus);

    cancelPlan = (): Promise<undefined> =>
        this.client
            .post("/cancel-plan")
            .then((_response: AxiosResponse) => undefined);

    reactivatePlan = (): Promise<undefined> =>
        this.client
            .post("/reactivate-plan")
            .then((_response: AxiosResponse) => undefined);

    deleteOrganization = (): Promise<undefined> =>
        this.client
            .post("/orgs/delete-organization")
            .then((_response: AxiosResponse) => undefined);

    getOrgAdmins = (): Promise<User[]> =>
        this.client
            .get("orgs/admins")
            .then((response: AxiosResponse) => response.data.admins as User[]);

    getOrgMembers = (): Promise<MemberResponse> =>
        this.client
            .get("orgs/members")
            .then((response: AxiosResponse) => response.data as MemberResponse);

    deleteSsoIntegration = (): Promise<undefined> =>
        this.client
            .delete("/orgs/sso")
            .then((_response: AxiosResponse) => undefined);

    updateDefaultPaymentMethod = (
        paymentMethod: string
    ): Promise<UpdatePaymentStatus> =>
        this.client
            .post("/update-default-payment", { paymentMethod })
            .then(
                (response: AxiosResponse) =>
                    response.data as UpdatePaymentStatus
            );

    scimSettings = (): Promise<ScimConfiguration> =>
        this.client
            .get("/orgs/scim")
            .then(
                (response: AxiosResponse) => response.data as ScimConfiguration
            );

    getBillingUpdateKey = (): Promise<BillingKey> =>
        this.client
            .get("/customer-billing-update-key")
            .then((response: AxiosResponse) => response.data as BillingKey);

    enableScim = (toEnable: boolean): Promise<ScimConfiguration> =>
        this.client
            .post("orgs/scim", { enable: toEnable })
            .then(
                (response: AxiosResponse) => response.data as ScimConfiguration
            );

    getApiToken = (): Promise<ApiConfiguration> =>
        this.client
            .get("api/token")
            .then(
                (response: AxiosResponse) => response.data as ApiConfiguration
            );

    generateAPiToken = (
        tokenExpiration: ApiTokenExpiration
    ): Promise<ApiConfiguration> =>
        this.client
            .post("api/token", { tokenExpiration })
            .then(
                (response: AxiosResponse) => response.data as ApiConfiguration
            );

    revokeApiToken = (): Promise<void> =>
        this.client
            .delete("api/token")
            .then((_response: AxiosResponse) => undefined);

    getMdmConfig = (): Promise<MdmConfiguration> =>
        this.client
            .get("/orgs/mdm")
            .then(
                (response: AxiosResponse) => response.data as MdmConfiguration
            );

    updateMdmConfig = (enable: boolean): Promise<MdmConfiguration> =>
        this.client
            .post("orgs/mdm", { enable })
            .then(
                (response: AxiosResponse) => response.data as MdmConfiguration
            );

    getThreatHunterUsers = (): Promise<ThreatHunterUser[]> =>
        this.client
            .get("/threat-hunter/users")
            .then(
                (response: AxiosResponse) =>
                    response.data.threatHunterUsers as ThreatHunterUser[]
            );

    addThreatHunterUser = (name: string, email: string): Promise<void> =>
        this.client
            .post("/threat-hunter/user", { name, email })
            .then((_response: AxiosResponse) => undefined);

    getEmailPreferences = (): Promise<EmailPreferences> =>
        this.client
            .get("/orgs/email-preferences")
            .then(
                (response: AxiosResponse) => response.data as EmailPreferences
            );

    updateEmailPreferences = (
        emailPreferences: EmailPreferences
    ): Promise<void> =>
        this.client
            .put("/orgs/email-preferences", emailPreferences)
            .then((_response: AxiosResponse) => undefined);

    getEmailTemplate = (type: EmailTemplateType): Promise<EmailTemplate> =>
        this.client
            .get(`/orgs/email-template?type=${type}`)
            .then((response: AxiosResponse) => response.data as EmailTemplate);

    updateEmailTemplate = (emailTemplate: EmailTemplate): Promise<void> =>
        this.client
            .put("/orgs/email-template", emailTemplate)
            .then((_response: AxiosResponse) => undefined);

    getNotificationsPreferences = (
        topic: InternalNotificationTopic
    ): Promise<NotificationPreference> =>
        this.client
            .get(`/orgs/notifications-preferences?topic=${topic}`)
            .then(
                (response: AxiosResponse) =>
                    response.data as NotificationPreference
            );

    updateNotificationsPreferences = (
        preferences: NotificationPreference
    ): Promise<void> =>
        this.client
            .put("/orgs/notifications-preferences", preferences)
            .then((_response: AxiosResponse) => undefined);

    getWebhookConfig = (): Promise<WebhookConfig> =>
        this.client
            .get("/orgs/webhook")
            .then((response: AxiosResponse) => response.data as WebhookConfig);

    getWebhookSecret = (): Promise<string> =>
        this.client
            .get("/orgs/webhook-secret")
            .then((response: AxiosResponse) => response.data.secret as string);

    saveWebhookConfig = (webhookConfig: WebhookConfig): Promise<void> =>
        this.client
            .post("/orgs/webhook", webhookConfig)
            .then((_response: AxiosResponse) => undefined);

    deleteWebhookConfig = (): Promise<undefined> =>
        this.client
            .delete("/orgs/webhook")
            .then((_response: AxiosResponse) => undefined);

    fetchAlerts = (filters?: {
        [key: string]: string;
    }): Promise<AlertRow[]> => {
        let url = "/alerts";

        if (filters) {
            Object.keys(filters).forEach((key) => {
                const value = filters[key];
                url += `?${key}=${value}`;
            });
        }

        const alerts = this.client
            .get(url)
            .then((response: AxiosResponse) => response.data as AlertRow[]);
        return alerts;
    };

    updateAlertStatus = (
        alertIds: string,
        status: AlertStatus
    ): Promise<void> =>
        this.client
            .post("/update-alerts", { alertIds, status })
            .then((_response: AxiosResponse) => undefined);

    fetchConditionalAccessConfig = (): Promise<ConditonalAccessConfig> =>
        this.client
            .get("/orgs/conditional-access/config")
            .then(
                (response: AxiosResponse) =>
                    response.data as ConditonalAccessConfig
            );

    saveConditionalAccessConfig = (
        conditionalAccessConfig: ConditonalAccessConfig
    ): Promise<void> =>
        this.client
            .post("/orgs/conditional-access/config", conditionalAccessConfig)
            .then((_response: AxiosResponse) => undefined);

    deleteConditionalAccess = (): Promise<undefined> =>
        this.client
            .delete("/orgs/conditional-access")
            .then((_response: AxiosResponse) => undefined);

    fetchConditionalAccessPolicy = (): Promise<ConditionalAccessPolicy> =>
        this.client
            .get("/orgs/conditional-access/policy")
            .then(
                (response: AxiosResponse) =>
                    response.data as ConditionalAccessPolicy
            );

    updateConditionalAccessPolicy = (
        conditionalAccessPolicy: ConditionalAccessPolicy
    ): Promise<void> =>
        this.client
            .put("/orgs/conditional-access/policy", conditionalAccessPolicy)
            .then((_response: AxiosResponse) => undefined);
}

export const axiosConfiguration: CreateAxiosDefaults = {
    responseType: "json",
    headers: {
        common: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
        },

        get: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
        },
    },
};

const axiosClient = axios.create(axiosConfiguration);
const apiClient = new ApiClient(axiosClient);
export { apiClient };
