import { useSelector } from "react-redux";
import { Link } from "components/Link";

import useQuery from "main/utils/useQuery";
import HeaderLayout from "main/HeaderLayout";
import ConfigurationLayout from "main/ConfigurationLayout";
import { AppRoutes } from "main/app/App";
import { SettingsPages } from "main/settings/SettingsPage";
import {
    ApplicationState,
    ErrorType,
    serverErrors,
    SessionState,
} from "main/app/types";
import Footer from "main/Footer";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface ErrorPageProps {
    errorType?: string | null;
    message?: string | null;
    description?: string | null;
}

export interface DisconnectedErrorPageProps extends ErrorPageProps {
    errorType: string | undefined;
    message: string;
    description: string;
    session: SessionState;
}

export const DisconnectedErrorPage: FC<DisconnectedErrorPageProps> = (
    props: DisconnectedErrorPageProps
) => {
    const { session, errorType, message, description } = props;

    const getErrorLink = (tab: string): JSX.Element => (
        <Link
            className="color-action-lt rubik"
            to={`${AppRoutes.settingsPath}?tab=${tab}`}
        >
            {description}
        </Link>
    );

    const getErrorDetails = (): JSX.Element | string => {
        switch (errorType) {
            case ErrorType.UPDATE_BILLING:
                return getErrorLink(SettingsPages.plan);
            case ErrorType.UPDATE_PAYMENT:
                return getErrorLink(SettingsPages.billing);
            default:
                return description;
        }
    };

    const errorDetails = getErrorDetails();

    return (
        <div className="flex-col flex-1">
            <div className="flex-col flex-1">
                <HeaderLayout session={session}>
                    <ConfigurationLayout>
                        <div className="form-title">An error has occurred</div>
                        {message && <div className="form-step">{message}</div>}
                        {errorDetails && (
                            <div className="form-instructions">
                                {errorDetails}
                            </div>
                        )}
                        <p>
                            Please refer to our&nbsp;
                            <Link to={{ pathname: AppRoutes.supportPath }}>
                                FAQ
                            </Link>
                            &nbsp;or contact us at&nbsp;
                            <Link to={{ pathname: AppRoutes.supportEmailPath }}>
                                support@iverify.io
                            </Link>
                        </p>
                    </ConfigurationLayout>
                </HeaderLayout>
            </div>
            <Footer />
        </div>
    );
};

const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
    const navigate = useNavigate();
    const session: SessionState = useSelector(
        (state: ApplicationState) => state.session
    );

    const query = useQuery();
    const { type: queryErrorType } = query;
    let errorType: string | undefined;
    let message: string | undefined;
    let description: string | undefined;

    if (!session?.error && !props?.message) {
        navigate(AppRoutes.dashboardPath);
    }

    if (
        queryErrorType &&
        Object.values(ErrorType).includes(queryErrorType as ErrorType)
    ) {
        errorType = queryErrorType;
        message = serverErrors[queryErrorType as ErrorType].message;
        description = serverErrors[queryErrorType as ErrorType].description;
    } else {
        errorType = props?.errorType ?? session?.error?.type;
        message = props?.message ?? session?.error?.message;
        description = props?.description ?? session?.error?.description;
    }

    return (
        <DisconnectedErrorPage
            session={session}
            errorType={errorType}
            message={message || "Unexpected error occurred."}
            description={
                description || "Please try signing in again or contact support."
            }
        />
    );
};

export const errorUrl = (errorType: ErrorType): string =>
    `/error?type=${errorType}`;

export default ErrorPage;
