import { DeviceInformation, DeviceRow } from "main/dashboard/types";
import { ModalState } from "components/modal/Modal";

export type UnenrollModalHook = {
    unenrollModalState: (rowSelections: DeviceRow[]) => ModalState;
};

export const useUnenrollModal = (): UnenrollModalHook => {
    const unenrollBody = (rowSelections: DeviceRow[]) => (
        <div className="flex-col">
            <div className="flex-ac">
                <ul className="item-details-list text-left mtl width-large height-small">
                    {rowSelections.map((device) => (
                        <li key={device.memberId}>
                            <div className="paragraph-two name">
                                {device.name}
                            </div>
                            <div className="paragraph-five model">
                                {(device as DeviceInformation).device}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );

    const unenrollModalState = (rowSelections: DeviceRow[]): ModalState => ({
        title: "Unenroll Member Devices",
        message: "The following individual(s) devices will be unenrolled:",
        alert: "Please note: listed devices will promptly lose iVerify access and their code will be invalidated.",
        cancelLabel: "Cancel",
        confirmLabel: "Unenroll",
        body: unenrollBody(rowSelections),
    });

    return { unenrollModalState };
};
