import { ChangeEvent, useState } from "react";
import { DeviceRow } from "main/dashboard/types";
import { Button } from "components/button/Button";
import { useEmailMembers } from "main/dashboard/hooks";

interface ActionDropdown {
    label: string;
    value: string;
}

export type Actions = "select" | "sendInvites" | "unenroll";

type IDeviceActions = {
    [key in Actions]: ActionDropdown;
};

export const DeviceActions: IDeviceActions = {
    select: { label: "Select option", value: "select-option" },
    sendInvites: { label: "Send invitation", value: "send-invitation" },
    unenroll: { label: "Unenroll", value: "unenroll" },
};

export const sendInvites = "Send invitation";
export const enableAction = "Select devices to enable options";

export type ActionMenuHook = {
    actionsMenu: (rowSelections: DeviceRow[]) => JSX.Element;
};

export const useActionMenu = (showModal: () => void): ActionMenuHook => {
    const [selectedAction, setSelectedAction] = useState<string | null>(null);
    const { loading: emailLoading, emailMembers } = useEmailMembers();

    const handleActionMenuItemClick = (
        event: ChangeEvent<HTMLSelectElement>
    ) => {
        const selection: Actions | null = event.target.value as Actions;
        if (selection === DeviceActions.select.value) {
            setSelectedAction(null);
        } else {
            setSelectedAction(selection);
        }
    };

    const handleAction = (rowSelections: DeviceRow[]) => () => {
        const deviceCodes = rowSelections.map((row: DeviceRow) => row.code);

        if (selectedAction === DeviceActions.sendInvites.value) {
            emailMembers(deviceCodes);
        }

        if (selectedAction === DeviceActions.unenroll.value) {
            showModal();
        }

        setSelectedAction(null);
    };

    const actionDropdown = (allowActions: boolean) => {
        const currentValue = `${
            allowActions
                ? selectedAction || DeviceActions.select.value
                : enableAction
        }`;

        return (
            <select
                data-testid="action-dropdown"
                value={currentValue}
                onChange={handleActionMenuItemClick}
                disabled={!allowActions}
                className={`dashboard-dropdown ${
                    allowActions
                        ? "dashboard-dropdown-selected"
                        : "dashboard-dropdown-not-selected"
                }`}
            >
                <option value={enableAction} hidden>
                    {enableAction}
                </option>
                <option value={DeviceActions.select.value}>
                    {DeviceActions.select.label}
                </option>
                <option value={DeviceActions.sendInvites.value}>
                    {DeviceActions.sendInvites.label}
                </option>
                <option value={DeviceActions.unenroll.value}>
                    {DeviceActions.unenroll.label}
                </option>
            </select>
        );
    };

    const actionsButton = (
        allowActions: boolean,
        rowSelections: DeviceRow[]
    ) => {
        const visibility =
            selectedAction && allowActions ? "visible" : "hidden";
        return (
            <Button
                className="h-8 py-0 px-2 self-center mr-1"
                appearance="tertiary"
                data-testid="action-button"
                style={{ visibility }}
                onClick={handleAction(rowSelections)}
                disabled={emailLoading}
                label={emailLoading ? "Sending..." : "Send"}
            />
        );
    };

    const actionsMenu = (rowSelections: DeviceRow[]) => {
        const allowActions = rowSelections.length > 0;
        return (
            <>
                <div className="dashboard-dropdown-menu self-center">
                    <div className="dashboard-dropdown-label">ACTION</div>
                    {actionDropdown(allowActions)}
                </div>
                {actionsButton(allowActions, rowSelections)}
            </>
        );
    };

    return { actionsMenu };
};
